import axios from "../utils/request";

const baseUrl = "/prod-api"

// 新增预约信息记录
export function addReserve(data) {
  return axios.post(baseUrl + "/platform/reserve", data);
}

// 新增满意度接口
export function addSatisfactionInfo(data) {
  return axios.post(baseUrl + "/platform/satisfactionInfo", data);
}

// 公示列表
// 代理记账申请
export function registerApi(data) {
  return axios.post(baseUrl + "/auth/bookkeepingRegister", data);
}
// 提交上传图片
export function addQualification(data) {
  return axios.post(baseUrl + '/platform/bookkeepingAgencyProcess/addQualification', data);
}

// 获取短信验证码
export function getSMScode(data) {
  return axios.post(baseUrl + "/auth/getSmsCaptcha", data);
}

// 获取验证码
export function getCodeImg(query) {
  return axios.get(baseUrl + "/code", query);
}

export function publicEnterpriseApi(query) {
  return axios.get(baseUrl + "/platform/bookkeepingAgencyProcess/publicEnterprise", query);
}

// 退出方法
export function logoutApi() {
  return axios.delete(baseUrl + "/auth/logout");
}
