import axios from "axios";
import qs from "qs";
import { Message } from "element-ui";

const instance = axios.create({
  //接口请求地址，是一个域名
  // baseURL: 'http://42.193.158.170:8083/',
  timeout: 10000,
});
//发送请求之前处理
instance.interceptors.request.use(
  (config) => {
    const plateCode = window.sessionStorage.getItem("plateCode");
    const token = window.sessionStorage.getItem("token");
    if (token) {
      // config.headers['token'] = token;
      config.headers.authorization = token;
    }
    // if (Cookies.get("token")) {
    //   //把token添加到请求头部
    //   config.headers['token'] = Cookies.get("token")
    // }

    // if (!config.url.includes("/login")) {
    //   if (!config.url.includes("/uploadGroupInfo")) {
    //     if (config.method === 'post') {
    //       // config.data = qs.stringify(config.data);
    //       config.data = {
    //         plateCode: plateCode,
    //         ...config.data
    //       }
    //     } else if (config.method === 'get') {
    //       config.params = {
    //         plateCode: plateCode,
    //         ...config.params
    //       }
    //     }
    //   }

    //   // console.log(config.url.uploadGroupInfo);

    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
//请求返回
instance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (
        response.status == 200 ||
        response.config.responseType === "arraybuffer"
      ) {
        return Promise.resolve(response);
      } else {
        Message({
          message: response.data.msg || "服务器错误!",
          type: "warning",
          duration: 3 * 1000,
        });
        return Promise.reject(response);
      }
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    return Promise.reject(error.response.status);
  }
);

//请求方法
const http = {
  post(url, params) {
    return instance.post(url, params, {
      transformRequest: [
        (params) => {
          return JSON.stringify(params);
        },
      ],
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  put(url, params) {
    return instance.put(url, params, {
      transformRequest: [
        (params) => {
          return JSON.stringify(params);
        },
      ],
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  //parm =>  {id:10}
  // http://localhost:8089/api/user?id=10
  get(url, params) {
    return instance.get(url, {
      params: params,
      // paramsSerializer: (params) => {
      //   // return qs.stringify(params)
      //   return params
      // }
    });
  },
  //parm =>  {id:10}
  // http://localhost:8089/api/user/10
  getRestApi(url, params) {
    let _params;
    if (Object.is(params, undefined || null)) {
      _params = "";
    } else {
      _params = "/";
      for (const key in params) {
        // console.log(key)
        // console.log(params[key])
        // eslint-disable-next-line no-prototype-builtins
        if (
          params.hasOwnProperty(key) &&
          params[key] !== null &&
          params[key] !== ""
        ) {
          _params += `${params[key]}/`;
        }
      }
      //去掉参数最后一位?
      _params = _params.substr(0, _params.length - 1);
    }
    // console.log(_params)
    if (_params) {
      return instance.get(`${url}${_params}`);
    } else {
      return instance.get(url);
    }
  },
  //parm =>  {id:10}
  // http://localhost:8089/api/user/10
  delete(url, params) {
    let _params;
    if (Object.is(params, undefined || null)) {
      _params = "";
    } else {
      _params = "/";
      for (const key in params) {
        // eslint-disable-next-line no-prototype-builtins
        if (
          params.hasOwnProperty(key) &&
          params[key] !== null &&
          params[key] !== ""
        ) {
          _params += `${params[key]}/`;
        }
      }
      //去掉参数最后一位?
      _params = _params.substr(0, _params.length - 1);
    }
    if (_params) {
      return instance.delete(`${url}${_params}`).catch((err) => {
        return Promise.reject(err);
      });
    } else {
      return instance.delete(url).catch((err) => {
        return Promise.reject(err);
      });
    }
  },
  upload(url, params) {
    return instance.post(url, params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  downFile(url, params) {
    return instance.post(url, params, {
      headers: {
        "Content-Type": "application/octet-stream;charset=UTF-8",
      },
    });
  },
  login(url, params) {
    return instance.post(url, params, {
      transformRequest: [
        (params) => {
          return qs.stringify(params);
        },
      ],
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
  //获取验证
  getImage(url) {
    return instance.post(url, null, {
      responseType: "arraybuffer",
    });
  },
};
export default http;
